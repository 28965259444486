import type { CSSProperties, ReactNode } from "react";

type Props = { style?: CSSProperties; children: ReactNode };

export default function DropdownContent({ children, style }: Props) {
  return (
    <li className="px-8 py-[3px]" style={style}>
      {children}
    </li>
  );
}
