import type { ModalProps } from "~/components/modal";
import FeatureForm from "~/components/features/form";
import Modal from "~/components/modal";

export default function ModalFeatureForm({ onClose }: ModalProps) {
  return (
    <Modal onExplicitClose={onClose} size="Large">
      <Modal.Header title="Request a Feature/Bug Fix" />
      <FeatureForm modal />
    </Modal>
  );
}
